import * as types from './actionType';
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
    key: "root",
    storage,
  };
// 초기 상태를 정의합니다.
const initialState = {
    admin: {

    },
    store : {

    },
    user: {
       
    },
    loading : false,
    language : {"name" : "한국어", "lang" : "ko"}, 
    alert : {
       visible : false,
       title : 'QED',
       message : '오류가 발생하였습니다.',
       callbackFunc : {}
    }
}

export const kioskState = (state = initialState, action) => {
    switch (action.type) {
        case types.SET_LANGUAGE : 
            return {
                ...state,
                language : action.params
            }
        case types.SET_LOADING : 
            return {
                ...state,
                loading : action.params
            }
        case types.SET_ADMIN_USER_INFO:
            localStorage.setItem("token", action.params.token)
            localStorage.setItem("refresh_token", action.params.refresh_token);

            return {
                ...state,
                admin : {
                    ...state.admin,
                    ...action.params.user_info,
                    token : action.params.token,
                    refresh_token: action.params.refresh_token,

                }
            }
        case types.SET_SELECTED_STORE:
            return {
                ...state,
                store : {
                    ...state.store,
                    selectedStore : action.params,
                }
            }
        case types.SET_STORE_SPACES:
            return {
                ...state,
                store : {
                    ...state.store,
                    storeSpaces : action.params,
                }
            }
        case types.USER_LOGOUT:
            return {
                ...state,
                user : {}
            }
        case types.SET_USERINFO:
            return {
                ...state,
                user : {
                    ...state.user,
                    ...action.params
                }
            }
        case types.SET_USAGESTATUS:
            return {
                ...state,
                user : {
                    ...state.user,
                    currentSpace : action.params
                }
            }
        case types.OPEN_ALERT:
            return {
                ...state,
                alert : action.params
            }
        case types.REMOVE_ALERT:
            return {
                ...state,
                alert : {
                    visible : false,
                    title : '',
                    message : '',
                    callbackFunc : {}
                }
            }
        case types.CLEAR_ALL_STATE:
            return {
                admin: {

                },
                store : {
            
                },
                user: {
                    
                },
                alert : {
                    visible : false,
                    title : '',
                    message : '',
                    callbackFunc : {}
                }
            }
        case types.CLEAR_STORE_STATE:
            return {
                ...state,
                store : {
            
                },
                user: {
                    
                },
                alert : {
                    visible : false,
                    title : '',
                    message : '',
                    callbackFunc : {}
                }
            }    
        case types.CLEAR_USER_STATE:
            return {
                ...state,
                user: {
                    
                },
                alert : {
                    visible : false,
                    title : 'QED',
                    message : '오류가 발생하였습니다.',
                    callbackFunc : {}
                }
            }   
        default:
            return state
    }
}

export const rootReducer = combineReducers({
    kioskState
});

export default persistReducer(persistConfig, rootReducer);
/*
 Action 의 종류들을 선언합니다.
 앞에 export 를 붙임으로서, 나중에 이것들을 불러올 때,
 import * as types from './ActionTypes' 를 할 수 있어요.
*/

export const SET_ADMIN_USER_INFO    = 'SET_ADMIN_USER_INFO';
export const SET_SELECTED_STORE     = 'SET_SELECTED_STORE';
export const SET_STORE_SPACES       = 'SET_STORE_SPACES';
export const USER_LOGOUT            = 'USER_LOGOUT'
export const SET_USERINFO           = 'SET_USERINFO'
export const SET_USAGESTATUS        = "SET_USAGESTATUS"
export const REMOVE_ALERT           = "REMOVE_ALERT";
export const OPEN_ALERT             = "OPEN_ALERT";
export const CLEAR_ALL_STATE        = "CLEAR_ALL_STATE";
export const CLEAR_STORE_STATE      = "CLEAR_STORE_STATE";
export const CLEAR_USER_STATE       = "CLEAR_USER_STATE";
export const SET_LOADING            = "SET_LOADING";
export const SET_LANGUAGE           = "SET_LANGUAGE";
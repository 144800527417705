import React from 'react';
import loadable from '@loadable/component'

import 'bootstrap/dist/css/bootstrap.min.css';
import '@css/common/common.css'

const Layout = loadable(() => import('@src/template/Layout'));

const App = () => {
  return (
    <React.Fragment>
      <Layout/>
    </React.Fragment>
  );
}

export default App;
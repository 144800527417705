import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import ko from './ko.json'
import en from './en.json'

const resources = {
  en: {
    translation: en
  },
  ko: {
    translation: ko
  }
};

i18n.use(initReactI18next)
  .init({
    resources,
    lng: JSON.parse(JSON.parse(localStorage.getItem("persist:root") || "{}" )?.kioskState || "{}")?.language?.lang || 'ko',
  });

export default i18n;
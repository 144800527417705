import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Provider  } from 'react-redux';
import store from '@store'
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import '@utils/i18n' // 다국어 지원 파일

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,

  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root'));
const persistor = persistStore(store);

root.render(
  <Provider store = {store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);